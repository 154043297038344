<template>
  <v-container fluid class="mb-16">
    <v-card height="100%">
      <v-row>
        <v-card-text style="background: white; height: 100%">
          <v-row class="ml-7" style="width: 96%">
            <v-col>
              <template>
                <v-toolbar elevation="2" style="background: #f5f5f5">
                  <v-toolbar-title class="mt-2 mb-2 ml-1">
                    Product Purchase Report
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <div class="pr-5 pb-5 pt-5">
                    <v-text-field
                      v-model="searchseller"
                      append-icon="mdi-magnify"
                      label="Search"
                      filled
                      rounded
                      dense
                      hide-details
                    ></v-text-field>
                  </div>

                  <div>
                    <v-btn text @click="print" class="ml-10">
                      <v-icon large color="black">mdi-printer </v-icon>
                    </v-btn>
                  </div>
                  <!-- <v-switch
                      v-model="singleExpand"
                      label="Single expand"
                      class="mt-5"
                    ></v-switch>  v-if="item.Brand_name != 'Individual' "-->
                </v-toolbar>

                <v-data-table
                  :headers="SellerHeader"
                  :items="specifications"
                  :search="searchseller"
                  class="elevation-1"
                  style="background: #f5f5f5 elevation-1"
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      shaped
                      icon
                      @click="viewSpecification(item)"
                      color="green"
                      dark
                    >
                      <v-icon dark> mdi-pencil</v-icon>
                    </v-btn>
                  </template>

                  <template v-slot:item.discount="{ item }">
                    <v-btn
                      shaped
                      text
                      @click="add_discount(item)"
                      color="green"
                    >
                      Discount
                    </v-btn>
                  </template>
                </v-data-table>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-row>
    </v-card>

    <v-dialog v-model="dialog" width="750px" scrollable>
      <v-card class="my-0">
        <v-card-title class="font-weight-bold"
          >Edit Specification
        </v-card-title>

        <v-card-text class="pb-0" style="height: 500px">
          <v-form ref="editSpecificationForm" v-model="validEditForm">
            <v-row>
              <v-col>
                <v-select
                  dense
                  v-model="editedItem.unit"
                  :items="select_val"
                  label="Product unit type"
                  :rules="[(v) => !!v || 'Unit is required']"
                  outlined
                ></v-select>
              </v-col>

              <v-col class="d-flex flex-row align-center">
                <v-text-field
                  v-model="editedItem.weight"
                  outlined
                  dense
                  label="Weight"
                  :rules="[(v) => !!v || 'Weight is required']"
                >
                  <p class="mb-0 mt-1 font-weight-bold" slot="append">
                    /{{ editedItem.unit }}
                  </p>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="editedItem.SKU"
                  label="Product SKU code"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="editedItem.price.purchase_price"
                  label="Product Purchase Price"
                  required
                  type="number"
                  :rules="[(v) => !!v || 'Purchase Price is required']"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="editedItem.price.price"
                  label="Product Selling Price"
                  required
                  type="number"
                  :rules="[(v) => !!v || 'Selling Price is required']"
                  outlined
                  dense
                ></v-text-field
              ></v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select
                  :items="colors"
                  label="Color"
                  dense
                  v-model="editedItem.color"
                  outlined
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  :items="sizes"
                  label="Size"
                  dense
                  v-model="editedItem.size"
                  outlined
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-menu
                  v-model="menufecmenu"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.manufacture_date"
                      label="Product manufacture Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      required
                      :rules="[(v) => !!v || 'Product manufacture Date']"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.manufacture_date"
                    @input="menufecmenu = false"
                    class="ma-0"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col>
                <v-menu
                  v-model="expmenu"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.expire"
                      label="Product Expire Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      required
                      :rules="[(v) => !!v || 'Product Expire Date']"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.expire"
                    @input="expmenu = false"
                    class="ma-0"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  dense
                  v-model="editedItem.warranty_unit"
                  :items="warranty_types"
                  label="Warranty type"
                  outlined
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="editedItem.warranty"
                  label="Warranty Amount"
                  type="number"
                  outlined
                  dense
                  :disabled="editedItem.warranty_unit == 'none'"
                >
                  <p slot="append" class="mb-0 mt-1 font-weight-bold">
                    /{{ editedItem.warranty_unit }}
                  </p>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                ><v-text-field
                  v-model="editedItem.vat"
                  label="VAT(%)"
                  required
                  type="number"
                  outlined
                  dense
                ></v-text-field
              ></v-col>
              <v-col
                ><v-text-field
                  v-model="editedItem.point.point"
                  label="Point Amount Per Hundred"
                  required
                  type="number"
                  outlined
                  dense
                ></v-text-field
              ></v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-menu
                  v-model="menu7"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.point.start_date"
                      label="Point Start Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      :disabled="editedItem.point.point == ''"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.point.start_date"
                    :min="new Date().toISOString().substr(0, 10)"
                    @input="menu7 = false"
                    class="ma-0"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  v-model="menu8"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.point.end_date"
                      label="Point End Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      :disabled="editedItem.point.point == ''"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.point.end_date"
                    :min="editedItem.point.start_date"
                    @input="menu8 = false"
                    class="ma-0"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select
                  dense
                  v-model="editedItem.discount.discount_type"
                  :items="discount_types"
                  label="Discount type"
                  outlined
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="editedItem.discount.amount"
                  label="Discount Amount"
                  required
                  type="number"
                  outlined
                  dense
                  :disabled="editedItem.discount.discount_type == 'none'"
                >
                  <v-icon
                    slot="append"
                    color="red"
                    v-if="editedItem.discount.discount_type == 'amount'"
                    >mdi-currency-bdt</v-icon
                  >
                  <v-icon
                    slot="append"
                    color="red"
                    v-if="editedItem.discount.discount_type == 'percentage'"
                    >mdi-percent</v-icon
                  >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-menu
                  v-model="menu5"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.discount.start_date"
                      label="Discount Start Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      :disabled="editedItem.discount_type == 'none'"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.discount.start_date"
                    :min="new Date().toISOString().substr(0, 10)"
                    @input="menu5 = false"
                    class="ma-0"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  v-model="menu6"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.discount.end_date"
                      label="Discount End Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      :disabled="editedItem.discount_type == 'none'"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.discount.end_date"
                    :min="editedItem.discount.start_date"
                    @input="menu6 = false"
                    class="ma-0"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-card style="min-width: 100%; border: 1px solid black">
                <v-card-title class="font-weight-medium pt-2"
                  >For Delivery</v-card-title
                >
                <v-card-text class="pb-0">
                  <v-row>
                    <v-col>
                      <v-select
                        dense
                        v-model="editedItem.delivery_info.measument_unit"
                        :items="delivery_units"
                        label="Product unit type"
                        :rules="[(v) => !!v || 'Unit is required']"
                        outlined
                      ></v-select>
                    </v-col>

                    <v-col>
                      <v-text-field
                        v-model="editedItem.delivery_info.weight"
                        outlined
                        dense
                        label="Weight"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.delivery_info.height"
                        outlined
                        dense
                        label="Height"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.delivery_info.width"
                        outlined
                        dense
                        label="Width"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.delivery_info.length"
                        outlined
                        dense
                        label="Length"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.delivery_info.charge_inside"
                              outlined
                              dense
                              label="Inside City Chrage"
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.delivery_info.charge_outside"
                              outlined
                              dense
                              label="Outside City Chrage"
                              type="number"
                            ></v-text-field>
                          </v-col>
                        </v-row> -->
                </v-card-text>
              </v-card>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <!-- <v-btn color="success" elevation="0" class="ml-3"
                  >Add New</v-btn
                > -->
          <v-spacer></v-spacer>

          <v-btn color="#854FFF" @click="saveEdit" :disabled="!validEditForm"
            >Save Edit</v-btn
          >
          <v-btn color="pink" @click="dialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ADD Discount -->
    <v-dialog v-model="addDiscountDialog" width="750px" scrollable>
      <v-card class="my-0">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>
            Product Name:
            {{ specdataobjdiscount.product_name }}
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text class="pb-0" style="height: 400px">
          <v-form ref="specificationForm" v-model="valid_add_discount">
            <v-row>
              <v-col>
                <v-select
                  dense
                  v-model="specdataobjdiscount.discount.discount_type"
                  :items="discount_types"
                  label="Discount type"
                  :rules="[(v) => !!v || 'Unit is required']"
                  outlined
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  v-model.number="specdataobjdiscount.discount.discount_amount"
                  label="Discount Amount"
                  type="number"
                  outlined
                  dense
                  :disabled="
                    specdataobjdiscount.discount.discount_type == 'none'
                  "
                  :rules="[(v) => !!v || 'Discount amount is required']"
                >
                  <v-icon
                    slot="append"
                    color="red"
                    v-if="
                      specdataobjdiscount.discount.discount_type == 'amount'
                    "
                    >mdi-currency-bdt</v-icon
                  >
                  <v-icon
                    slot="append"
                    color="red"
                    v-if="
                      specdataobjdiscount.discount.discount_type == 'percentage'
                    "
                    >mdi-percent</v-icon
                  >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-menu
                  v-model="menudist"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="specdataobjdiscount.discount.discount_start_date"
                      label="Discount Start Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      :disabled="
                        specdataobjdiscount.discount.discount_type == 'none' ||
                        !specdataobjdiscount.discount.discount_amount ||
                        specdataobjdiscount.discount.discount_amount < 0 ||
                        typeof specdataobjdiscount.discount.discount_amount !=
                          'number'
                      "
                      :rules="[(v) => !!v || 'Start date is required']"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="specdataobjdiscount.discount.discount_start_date"
                    :min="new Date().toISOString().substr(0, 10)"
                    @input="menudist = false"
                    class="ma-0"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  v-model="menu2dist"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="specdataobjdiscount.discount.discount_end_date"
                      label="Discount End Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      :disabled="
                        specdataobjdiscount.discount.discount_type == 'none' ||
                        !specdataobjdiscount.discount.discount_amount ||
                        specdataobjdiscount.discount.discount_amount < 0 ||
                        typeof specdataobjdiscount.discount.discount_amount !=
                          'number'
                      "
                      :rules="[(v) => !!v || 'End date is required']"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="specdataobjdiscount.discount.discount_end_date"
                    :min="specdataobjdiscount.discount.discount_start_date"
                    @input="menu2dist = false"
                    class="ma-0"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <!-- <v-btn color="#854FFF" elevation="0" class="ml-3"
                  >Add New</v-btn
                >       :rules="[(v) => v.length >= 4 && v.length != 0|| 'Min 4 characters']"        -->
          <v-spacer></v-spacer>
          <v-btn color="red" @click="addDiscountDialog = false" elevation="0"
            >Cancel</v-btn
          >

          <v-btn
            color="blue darken-1"
            @click="saveDist()"
            elevation="0"
            :disabled="!valid_add_discount"
          >
            Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>



<script>
export default {
  data: () => ({
    dialog: false,
    editdialog: false,
    loading: true,
    searchseller: "",

    menu: false,
    menu2: false,
    menu3: false,
    menu4: false,
    menu5: false,
    menu6: false,
    menu7: false,
    menu8: false,
    expmenu: false,
    menufecmenu: false,

    validEditForm: false,

    discount_types: ["none", "amount", "percentage"],
    sizes: ["none", "S", "M", "L", "XL", "XXL", "XXXL"],
    select_val: ["kg", "gm", "pcs", "dozen", "liter", "ml"],
    delivery_units: ["kg", "gm"],
    warranty_types: ["none", "day", "month", "year"],
    colors: [
      "none",
      "white",
      "deep-purple",
      "indigo",
      "yellow",
      "light-blue",
      "cyan",
      "orange",
      "red",
      "teal",
      "light-green",
      "pink",
      "purple",
      "blue",
      "lime",
      "amber",
      "deep-orange",
      "green",
      "blue-grey",
      "brown",
      "grey",
      "silver",
      "black",
    ],

    SellerHeader: [
      //   {
      //   text: "Date",
      //   value: "seller_name",
      //   sortable: false,
      // },
      {
        text: "SKU",
        value: "product_sku",
        sortable: false,
      },
      //{ text: "Barcode", value: "product_barcode", sortable: false },
      { text: "Product Name", value: "product_name", sortable: false },
      { text: "Brand ", value: "product_brand", sortable: false },
      { text: "In Quantity", value: "credit_sum", sortable: false },
      { text: "Out Quantity", value: "debit_sum", sortable: false },
      { text: "Purchase Price", value: "purchase_price", sortable: false },
      { text: "Selling Price", value: "selling_price", sortable: false },
      { text: "DIS Amount", value: "discount.discount_amount", sortable: false },
      { text: "DIS Type", value: "discount.discount_type", sortable: false },
      { text: "", value: "discount", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],

    tickets: [],
    contact: {},

    specifications: [],

    sellerPdetails: {},

    editedItem: {
      discount: {},
      point: {},
      delivery_info: {},
      price: {},
    },

    specdataobjdiscount: {
      discount: {
        discount_type: "none",
        discount_amount: 0,
        discount_start_date: "",
        discount_end_date: "",
      },
    },
    product_dist: {
      discount_type: "none",
      discount_amount: 0,
      discount_start_date: "",
      discount_end_date: "",
    },
    addDiscountDialog: false,
    valid_add_discount: false,
    menudist: false,
    menu2dist: false,
  }),

  methods: {
    //This api is for demo testing with child site data
    initialize() {
      axios
        .get("productdetails/purchase_reports/")
        .then((response) => {
          let beforeCustomization = [];
          beforeCustomization = response.data.data;
          beforeCustomization.forEach((element) => {
            element.forEach((childElement) => {
              this.specifications.push(childElement);
            });
          });
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    closedialog() {
      this.dialog = false;
    },

    viewSpecification(item) {
      let routeData = this.$router.resolve({
        name: `/dashboard/pages/specification/${item.product_id}`,
      });
      routeData.href = `/dashboard/pages/specification/?id=${item.product_id}&unit=${item.unit}`;
      window.open(routeData.href, "_blank");
      // console.log("ITEM ", item);
      // axios
      //   .get(`productdetails/showspec/${item.product_id}/`)
      //   .then((response) => {
      //     let result = response.data.data.find((obj) => {
      //       return obj.id == item.specification_id;
      //     });

      //     Object.assign(this.editedItem, result);

      //     this.dialog = true;

      //     console.log(this.editedItem);
      //   });
    },

    // This Link will be Change for link change
    print() {
      let routeData = this.$router.resolve({
        name: `site/get_productreport_pdf/`,
        // name: `http://127.0.0.1:8000/site/get_productreport_pdf/`
      });
      routeData.href = axios.defaults.baseURL + `site/get_productreport_pdf/`;
      // routeData.href = `http://127.0.0.1:8000/site/get_productreport_pdf/`;
      window.open(routeData.href, "_blank");
    },

    saveEdit() {
      let editedSpecification = {
        color: this.editedItem.color,
        delivery_height: this.editedItem.delivery_info.height,
        delivery_inside_city_charge: this.editedItem.delivery_info
          .charge_inside,
        delivery_length: this.editedItem.delivery_info.length,
        delivery_outside_city_charge: this.editedItem.delivery_info
          .charge_outside,
        delivery_product_unit: this.editedItem.delivery_info.measument_unit,
        delivery_weight: this.editedItem.delivery_info.weight,
        delivery_width: this.editedItem.delivery_info.width,
        discount_amount: this.editedItem.discount.amount,
        discount_end_date: this.editedItem.discount.end_date,
        discount_start_date: this.editedItem.discount.start_date,
        discount_type: this.editedItem.discount.discount_type,
        point_amount: this.editedItem.point.point,
        point_end_date: this.editedItem.point.end_date,
        point_start_date: this.editedItem.point.start_date,
        price: this.editedItem.price.price,
        product_unit: this.editedItem.unit,
        purchase_price: this.editedItem.price.purchase_price,
        size: this.editedItem.size,
        warranty: this.editedItem.warranty,
        warranty_unit: this.editedItem.warranty_unit,
        weight: this.editedItem.weight,
        vat: this.editedItem.vat,
        SKU: this.editedItem.SKU,
        expire: this.editedItem.expire,
        manufacture_date: this.editedItem.manufacture_date,
      };
      axios
        .post(
          `productdetails/edit_specification/${this.editedItem.id}/`,
          editedSpecification
        )
        .then((response) => {
          // console.log("After Edit ", response.data);
          if (response.data.success) {
            // comment  Object.assign(this.contract[this.editedIndex], editedSpecification);

            this.dialog = false;
            this.text = response.data.message;
            //comment this.$refs.editSpecificationForm.reset();
            //this.text = "Specification has been uploaded successfully";
            this.color = "success";
            this.snackbar = true;
            // this.$router.go(this.$router.currentRoute);
          } else {
            //this.text = "Something went wrong !!";
            this.text = response.data.message;
            this.color = "error";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.text = "Something went wrong !!";
          //this.text = response.data.message;
          this.color = "error";
          this.snackbar = true;
        });
    },

    add_discount(item) {
      this.addDiscountDialog = true;
      Object.assign(this.specdataobjdiscount, item);
      // console.log(this.specdataobjdiscount);
    },
    Canceldiscount() {
      this.addDiscountDialog = false;
    },
    saveDist(specdataobjdiscount) {
      let datas = {
        discount_type: this.specdataobjdiscount.discount.discount_type,
        discount_amount: this.specdataobjdiscount.discount.discount_amount,
        discount_start_date: this.specdataobjdiscount.discount.discount_start_date,
        discount_end_date: this.specdataobjdiscount.discount.discount_end_date,
        product_id: this.specdataobjdiscount.product_id,
      };
       //console.log("this is item", datas);

      axios
        .post(`site/add_discount/${this.specdataobjdiscount.specification_id}/`, datas)
        .then((response) => {
          if (response.data.success) {
            this.text = response.data.message;
            this.color = "success";
            this.snackbar = true;
            this.addDiscountDialog = false;
          } else {
            this.text = response.data.message;
            this.color = "error";
            this.snackbar = true;
          }
        });
    },
  },

  mounted() {
    this.initialize();
  },
};
</script>



<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>